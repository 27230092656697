footer {
    padding: 18px 0;
    text-align: center;
    background: #000428; /* fallback for old browsers */
    background: -webkit-linear-gradient(-90deg, #170a2a 55%, #f8b231);
    background: linear-gradient(-90deg, #2baaaa, #170a2a 25%);
  }
  
  ul.social-buttons {
    margin-bottom: 0;
  }
  
  ul.social-buttons li a {
    font-size: 20px;
    line-height: 40px;
    display: block;
    width: 40px;
    height: 40px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    color: white;
    border-radius: 100%;
    outline: none;
    background-color: #1e3e5f;
  }
  
  ul.social-buttons li a:active,
  ul.social-buttons li a:focus,
  ul.social-buttons li a:hover {
    background-color: #f2c335;
  }