/* @font-face {
    font-family: Cloister;
    src: url(../../assets/fonts/CloisterBlack.ttf);
  }
  
  @font-face {
    font-family: Coronet;
    src: url(../../assets/fonts/Coronet_Bold.ttf);
  } */
  
  .mainr {
    border: solid 15px rgba(255, 255, 255, 0);
  }
  .xline {
    border-bottom: solid 1px rgba(255, 255, 255, 0) !important;
    font-weight: bold;
  }
  .uni-title {
    font-family: Cloister;
    color: #1c488d;
    font-size: 65px;
  }
  .uni-subtitle {
    font-size: large;
  }
  .diploma {
    font-family: Cloister;
    color: #d8b36a;
    font-size: 14vmin;
    -webkit-print-color-adjust: exact;
  }
  .cert-text {
    font-size: larger;
    font-weight: bold;
  }
  .cert-data {
    font-size: larger;
  }
  .award {
    font-family: Coronet;
    color: #1c488d;
    font-size: 6vmin;
  }
  .recognition {
    font-family: "Calibri";
    font-weight: normal;
    font-size: 2vmin;
  }
  .myDivr {
    position: relative;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0);
    /* background-color: rgba(199, 215, 230, 0.2); */
  }
  .myDivr .bgr {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background: url(../../assets/EITSLogo.png) center center; */
    background-size: contain;
    background-repeat: no-repeat;
    /* opacity: 0.2; */
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(199, 215, 230, 0.2);
  }
  .tablet,
  .tr,
  .th,
  .td {
    border: 1px solid rgba(255, 255, 255, 0) !important;
  }
  
  .th {
    font-family: Cloister;
    color: #1c488d;
    font-size: 3vmin;
    font-weight: normal;
    -webkit-print-color-adjust: exact;
  }
  @media print {
    #Header,
    footer {
      display: none !important;
    }
    .noprint {
      visibility: hidden !important;
    }
    * {
      color-adjust: exact;
      -webkit-print-color-adjust: exact;
    }
    .pagecut {
      page-break-after: always;
    }
    .row,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .trans {
      background-color: rgba(255, 255, 255, 0) !important;
    }
  }