
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Raleway:wght@500&family=Roboto:ital,wght@0,300;1,300;1,400&display=swap');
.nav-link {
  color: white !important;
}

.nav-link :hover {
  color: rgb(240, 179, 66) !important;
}

.brand {
  font-family: "Fira Sans Condensed", sans-serif;
  font-size: 2.5vh;
  text-transform: uppercase;
}

#mainNav {
  background: #000428; /* fallback for old browsers */
  background: -webkit-linear-gradient(-45deg, #170a2a, #f8b231 95%) !important;
  background: linear-gradient(-45deg, #170a2a, #2d567c 95%) !important;
}